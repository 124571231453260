/* mobile navabar css  */

.hamburger {
   
    width: 24px;
    height: 24px;
    transition: all 0.25s;
    /* position: relative; */
    
    
  }
  
  .hamtop,
  .hammiddle,
  .hambottom {
    position: absolute;
 
    width: 24px;
    height: 2px;
    background:#1466A2;
    transform: rotate(0);
    transition: all 0.5s;
  }
  
  .hammiddle {
    transform: translateY(7px);
  }
  .hambottom {
    transform: translateY(14px);
  }
  
  .open {
    transform: rotate(90 deg);
    transform: translateY(0px);
  }
  
  .open .hamtop {
    transform: rotate(45deg) translateY(6px) translate(6px);
  }
  
  .open .hammiddle {
    display: none;
  }
  
  .open .hambottom {
    transform: rotate(-45deg) translateY(6px) translate(-6px);
  }






